// import React, { useState, useEffect } from 'react';
// import '../../vendors/horizontal-layout/style.css';
// import logoMini from './logo-mini.svg';
// // import logoMini from './logo-mini-palus.svg';
// // import logoMini from './logo-mini-les.svg';
// // import logoMini from './logo-mini-anglo.svg';
// // import logoMini from './Purna-School-Logo.svg';
// // import logoMini from './logo-turchi.svg';
// // import logoMini from './logo-mini-bsb.svg';
// // import logoMini from './logo-mini-mtes.svg';
// // import logoMini from './logo-mini-pvdpssp.svg';
// // import logoMini from './logo-mini-narnde.svg';


// import demoSchoolLogMini from '../Login/Purna-School-Logo.png'
// import TestMenu from './TestMenu';
// import { useSelector } from "react-redux";
// import ExitToAppIcon from '@mui/icons-material/ExitToApp';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// import axios from 'axios';
// import MenuIcon from '@mui/icons-material/Menu';
// import WidgetsIcon from '@mui/icons-material/Widgets';
// import LockIcon from '@mui/icons-material/Lock';
// import { login, logout, accLogOut } from '../../reduxFeature/user';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from "react-router";
// import {
//     PrincipalMenu, ClerkMenu, SupervisorMenu, TeacherMenu, AdminMenu, TermFee, sansthaAcademic,
//     sansthaClerk, dEdCollege, EEAccount, LibraryMenu, LibraryForPrincipalMenu, HostelMenu, DengiMenu
// } from "../Menu/Service/ToggleMenu";
// // Principal Menu imported
// import PrinciaplAcademicsJSON from "./Service/PrinciaplAcademicsJSON";
// import PrincipalAccountJSON from "./Service/PrincipalAccountJSON";
// // Clerk Menu imported
// import ClerkAccountJSON from "./Service/ClerkAccountJSON";
// import ClerkAcademicsJSON from "./Service/ClerkAcademicsJSON";
// // Supervisor Menu imported
// import SupervisorAcademicsJSON from "./Service/SupervisorAcademicsJSON";
// // Teacher Menu imported
// import TeacherAcademicsJSON from "./Service/TeacherAcademicsJSON";
// //Admin Menu Imported
// import TestingMenuId from "./Service/TestingMenuId";

// import LibraryAcademicsJSON from './Service/LibraryAcademicJSON';

// import mobileApp from './Service/MobileAppWorkingJSON';

// // Sanstha Menu Imported
// import SansthaAcademicJSON from "./Service/SansthaAcademicJSON";
// import SansthaAccountJSON from "./Service/SansthaAccountJSON";

// import SansthaEndowmentJSON from './Service/SansthaEndowmentJSON';

// import DedCollegeJSON from "./Service/DedCollegeJSON";

// import EEAccountJSON from './Service/EEAccountJson';

// import DengiAccountJSON from './Service/DengiAccountJSON';
// //notification APIURL
// import { getServiceFormUserFlagDataCountAPIURL } from '../../ServicePanel/Service/allEnquiryAPIURL';

// import { getNoticeDataAPIURL } from '../../ServicePanel/Service/SystemUpdateAPIURL';
// import SansthaAdminModal from '../../Sanstha/SansthaAccount/SansthaAdminModal';
// import budgetJSON from './Service/BudgetAcademicJSON';
// import budgetSansthaJson from './Service/BudgetSansthaJSON';
// import payrolAcademicJSON from './Service/PayrolAcademicJSON';
// import HostelAcademicJSON from './Service/HostelAcademicJSON';
// import InventoryStoreJSON from './Service/InventoryStoreJSON';

// const MasterHeader = () => {
//     const authUser = useSelector((state) => state.user.value);

//     const navigate = useNavigate();
//     const dispatch = useDispatch();

//     const [isMessage, setIsMessage] = useState(0)
//     //console.log(isMessage);

//     const [notificationCount, setNotificationCount] = useState(0)
//     const [systemMessageData, setSystemMessageData] = useState([])

//     const [showModal, setShowModal] = useState(false);

//     const logoutApp = () => {
//         sessionStorage.removeItem("inst-sss-d");
//         sessionStorage.removeItem("menuName");
//         dispatch(logout());
//         navigate("/");
//     }

//     //Auto Logout after 15 min Start    

//     // var timer;
//     // const autoLogout = () => {
//     //     sessionStorage.removeItem("inst-sss-d");
//     //     sessionStorage.removeItem("menuName");
//     //     dispatch(logout());
//     //     navigate("/");        
//     // }

//     // const resetTimer = () => {  
//     //     timer=0;      
//     //     clearTimeout(timer);
//     //     console.log(timer);
//     //     timer = setTimeout(autoLogout, 5000);
//     // }

//     // document.onmousemove =()=>{
//     //     resetTimer();
//     // }
//     // document.onkeydown =()=>{
//     //     resetTimer();
//     // }
//     // document.onkeyup =()=>{
//     //     resetTimer();
//     // }


//     //Auto Logout after 15 min End


//     const getNotificationCount = async () => {
//         await axios.get(`${getServiceFormUserFlagDataCountAPIURL}?roleId=${authUser.roleId}&bid=${authUser.branchId}&sid=${authUser.deptId}`)
//             .then((res) => {
//                 if (res.data !== null) {
//                     //console.log(res.data)
//                     setNotificationCount(res.data)
//                 }
//             })
//             .catch((err) => {
//                 console.log(err)
//             })
//     }

//     const getSystemMessage = async () => {
//         await axios.get(getNoticeDataAPIURL)
//             .then((res) => {
//                 if (res.data !== null) {
//                     setSystemMessageData(res.data)
//                 }
//             })
//             .catch((err) => {
//                 console.log(err)
//             })
//     }

//     useEffect(() => {
//         if (authUser.roleId !== null && authUser.branchId !== null && authUser.deptId !== null) {
//             getNotificationCount();
//             getSystemMessage();
//         }
//     }, [authUser])

//     //Auto Logout after 15 min Start    

//     const [counter, setCounter] = useState(0)

//     var timer;
//     const autoNotificationCall = () => {
//         setCounter(counter + 1);
//         getNotificationCount();
//         getSystemMessage();
//     }

//     const resetTimer = () => {
//         timer = 0;
//         clearTimeout(timer);
//         //console.log(timer);
//         timer = setTimeout(autoNotificationCall, 6000);
//     }

//     useEffect(() => {
//         if (authUser.roleId !== null && authUser.branchId !== null && authUser.deptId !== null) {
//             resetTimer();
//         }
//     }, [counter, authUser])

//     //Auto Logout after 15 min End

//     const [selectedMenu, setSelectedMenu] = useState("Menu");
//     const [sendingMenu, setSendingMenu] = useState([]);

//     const [toggleMenu, setToggleMenu] = useState([{}]);

//     const [num, setNum] = useState(1);

//     const [kirdNm, setKirdNm] = useState("");


//     useEffect(() => {
//         const sessionMenuName = sessionStorage.getItem("menuName");
//         //console.log("role id : " + authUser.roleId+"dept id : " + authUser.deptId);
//         //console.log(sessionMenuName);
//         if (authUser.accApl === 1) {
//             if ((authUser.roleId === 3 && authUser.deptId === 5)) {
//                 if (sessionMenuName === "Budget") {
//                     setToggleMenu(dEdCollege);
//                     setSendingMenu(budgetJSON);
//                     setSelectedMenu("Budget");
//                     setKirdNm("");
//                 }
//                 else {
//                     setToggleMenu(dEdCollege);
//                     setSendingMenu(DedCollegeJSON);
//                     setSelectedMenu("Account");
//                     setKirdNm("");
//                 }

//             }
//             else if (authUser.roleId === 1) {
//                 if (authUser.deptId === 5 || authUser.deptId === 16 || authUser.deptId === 13) {
//                     if (sessionMenuName === "Budget") {
//                         setToggleMenu(dEdCollege);
//                         setSendingMenu(budgetJSON);
//                         setSelectedMenu("Budget");
//                         setKirdNm("");
//                     }
//                     else {
//                         setToggleMenu(dEdCollege);
//                         setSendingMenu(PrincipalAccountJSON);
//                         setSelectedMenu("Account");
//                         setKirdNm("");
//                     }
//                 }
//                 else if (authUser.deptId === 14) {
//                     if (sessionMenuName === "Budget") {
//                         setToggleMenu(HostelMenu);
//                         setSendingMenu(budgetJSON);
//                         setSelectedMenu("Budget");
//                         setKirdNm("");
//                     }
//                     else if (sessionMenuName === "Hostel") {
//                         setToggleMenu(HostelMenu);
//                         setSendingMenu(HostelAcademicJSON);
//                         setSelectedMenu("Hostel");
//                         setKirdNm("");
//                     }
//                     else {
//                         setToggleMenu(HostelMenu);
//                         setSendingMenu(PrincipalAccountJSON);
//                         setSelectedMenu("Account");
//                         setKirdNm("");
//                     }
//                 }
//                 else {
//                     setToggleMenu(TermFee);
//                     setSendingMenu(PrincipalAccountJSON);
//                     setSelectedMenu("Account");
//                     setKirdNm("");
//                 }
//             }
//             else if (authUser.roleId === 3) {
//                 if (authUser.deptId === 16 || authUser.deptId === 13) {
//                     if (sessionMenuName === "Budget") {
//                         setToggleMenu(dEdCollege);
//                         setSendingMenu(budgetJSON);
//                         setSelectedMenu("Budget");
//                         setKirdNm("");
//                     }
//                     else {
//                         setToggleMenu(dEdCollege);
//                         setSendingMenu(ClerkAccountJSON);
//                         setSelectedMenu("Account");
//                         setKirdNm("");
//                     }
//                 }
//                 else if (authUser.deptId === 14) {
//                     if (sessionMenuName === "Budget") {
//                         setToggleMenu(HostelMenu);
//                         setSendingMenu(budgetJSON);
//                         setSelectedMenu("Budget");
//                         setKirdNm("");
//                     }
//                     else if (sessionMenuName === "Hostel") {
//                         setToggleMenu(HostelMenu);
//                         setSendingMenu(HostelAcademicJSON);
//                         setSelectedMenu("Hostel");
//                         setKirdNm("");
//                     }
//                     else {
//                         setToggleMenu(HostelMenu);
//                         setSendingMenu(PrincipalAccountJSON);
//                         setSelectedMenu("Account");
//                         setKirdNm("");
//                     }
//                 }
//                 else if (authUser.deptId === 5) {
//                     if (sessionMenuName === "Account") {
//                         setToggleMenu(dEdCollege);
//                         setSendingMenu(DedCollegeJSON);
//                         setSelectedMenu("Account");
//                         setKirdNm("");
//                         navigate("/Home/ac04/0");
//                     }
//                     else if (sessionMenuName === "Budget") {
//                         setToggleMenu(dEdCollege);
//                         setSendingMenu(budgetJSON);
//                         setSelectedMenu("Budget");
//                         setKirdNm("");
//                         navigate("/Home/BudgetDashboard");
//                     }
//                 }
//                 else {
//                     setToggleMenu(TermFee);
//                     setSendingMenu(ClerkAccountJSON);
//                     setSelectedMenu("Account");
//                     setKirdNm("");
//                 }
//             }
//             else if (authUser.roleId === 5) {
//                 setToggleMenu(TermFee);
//                 setSendingMenu(TestingMenuId);
//                 setSelectedMenu("Account");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 7) {
//                 if (sessionMenuName === "Budget") {
//                     setToggleMenu(sansthaClerk);
//                     setSendingMenu(budgetSansthaJson);
//                     setSelectedMenu("Budget");
//                     setKirdNm("");
//                 }
//                 else {
//                     setToggleMenu(sansthaClerk);
//                     setSendingMenu(SansthaAccountJSON);
//                     setSelectedMenu("Account");
//                     setKirdNm("");
//                 }
//             }
//             else if (authUser.roleId === 8) {
//                 if (sessionMenuName === "Budget") {
//                     setToggleMenu(EEAccount);
//                     setSendingMenu(budgetJSON);
//                     setSelectedMenu("Budget");
//                     setKirdNm("");
//                 }
//                 else {
//                     setToggleMenu(EEAccount);
//                     setSendingMenu(EEAccountJSON);
//                     setSelectedMenu("Account");
//                     setKirdNm("");
//                 }
//             }
//             else if (authUser.roleId === 10) {
//                 setToggleMenu(DengiMenu);
//                 setSendingMenu(DengiAccountJSON);
//                 setSelectedMenu("Account");
//                 setKirdNm("");
//             }
//         }
//         else if (sessionMenuName === "Academics") {
//             if (authUser.roleId === 1) {
//                 if (authUser.deptId === 3 || authUser.deptId === 4) {
//                     setToggleMenu(LibraryForPrincipalMenu);
//                     setSendingMenu(PrinciaplAcademicsJSON);
//                     setSelectedMenu("Academics");
//                     setKirdNm("");
//                 }
//                 else {
//                     setToggleMenu(PrincipalMenu);
//                     setSendingMenu(PrinciaplAcademicsJSON);
//                     setSelectedMenu("Academics");
//                     setKirdNm("");
//                 }
//             }
//             else if (authUser.roleId === 2) {
//                 setToggleMenu(SupervisorMenu);
//                 setSendingMenu(SupervisorAcademicsJSON);
//                 setSelectedMenu("Academics");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 3) {
//                 setToggleMenu(ClerkMenu);
//                 setSendingMenu(ClerkAcademicsJSON);
//                 setSelectedMenu("Academics");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 4) {
//                 setToggleMenu(TeacherMenu);
//                 setSendingMenu(TeacherAcademicsJSON);
//                 setSelectedMenu("Academics");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 5) {
//                 setToggleMenu(AdminMenu);
//                 setSendingMenu(TestingMenuId);
//                 setSelectedMenu("Master");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 6) {
//                 setToggleMenu(sansthaAcademic);
//                 setSendingMenu(SansthaAcademicJSON);
//                 setSelectedMenu("Sanstha");
//                 setKirdNm("");
//             }
//         }
//         else if (sessionMenuName === "Endowment") {
//             if (authUser.roleId === 6) {
//                 setToggleMenu(sansthaAcademic);
//                 setSendingMenu(SansthaEndowmentJSON);
//                 setSelectedMenu("Endowment");
//                 setKirdNm("");
//             }
//         }
//         else if (sessionMenuName === "Account") {
//             if (authUser.roleId === 3 && authUser.deptId === 5 && authUser.accApl === 0) {
//                 setToggleMenu(ClerkMenu);
//                 setSendingMenu(ClerkAccountJSON);
//                 setSelectedMenu("Account");
//                 setKirdNm(" (शाळा किर्द.)");
//             } else if (authUser.roleId === 3 && authUser.deptId === 5) {
//                 setToggleMenu(dEdCollege);
//                 setSendingMenu(DedCollegeJSON);
//                 setSelectedMenu("Account");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 1) {
//                 if (authUser.deptId === 3 || authUser.deptId === 4) {
//                     setToggleMenu(LibraryForPrincipalMenu);
//                     setSendingMenu(PrincipalAccountJSON);
//                     setSelectedMenu("Account");
//                     setKirdNm(" (शाळा किर्द.)");
//                 }
//                 else {
//                     setToggleMenu(PrincipalMenu);
//                     setSendingMenu(PrincipalAccountJSON);
//                     setSelectedMenu("Account");
//                     setKirdNm(" (शाळा किर्द.)");
//                 }

//             }
//             else if (authUser.roleId === 3) {
//                 setToggleMenu(ClerkMenu);
//                 setSendingMenu(ClerkAccountJSON);
//                 setSelectedMenu("Account");
//                 setKirdNm(" (शाळा किर्द.)");
//                 //navigate("/Home/ac04/0");
//             }
//             else if (authUser.roleId === 5) {
//                 setToggleMenu(AdminMenu);
//                 setSendingMenu(TestingMenuId);
//                 setSelectedMenu("Account");
//                 setKirdNm(" (शाळा किर्द.)");

//             }
//             else if (authUser.roleId === 7) {
//                 setToggleMenu(sansthaClerk);
//                 setSendingMenu(SansthaAccountJSON);
//                 setSelectedMenu("Account");
//                 setKirdNm(" (शाळा किर्द.)");

//             }
//             else if (authUser.roleId === 8) {
//                 setToggleMenu(EEAccount);
//                 setSendingMenu(EEAccountJSON);
//                 setSelectedMenu("Account");
//                 setKirdNm("");
//             }
//         }
//         else if (sessionMenuName === "Library") {
//             if (authUser.roleId == 1) {
//                 if (authUser.deptId === 3 || authUser.deptId === 4) {
//                     setToggleMenu(LibraryForPrincipalMenu);
//                     setSendingMenu(LibraryAcademicsJSON);
//                     setSelectedMenu("Library");
//                     setKirdNm("");
//                 }
//             }
//             else if (authUser.roleId === 9) {
//                 setToggleMenu(LibraryMenu);
//                 setSendingMenu(LibraryAcademicsJSON);
//                 setSelectedMenu("Library");
//                 setKirdNm("");
//             }
//         }
//         else if (sessionMenuName === "Mobile App") {
//             if (authUser.roleId == 1) {
//                 setToggleMenu(LibraryForPrincipalMenu);
//                 setSendingMenu(mobileApp);
//                 setSelectedMenu("Mobile App");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 3) {
//                 setToggleMenu(ClerkMenu);
//                 setSendingMenu(mobileApp);
//                 setSelectedMenu("Mobile App");
//                 setKirdNm("");
//             }
//         }
//         else if (sessionMenuName === "Budget") {
//             if (authUser.roleId === 1) {
//                 if (authUser.deptId === 3 || authUser.deptId === 4) {
//                     setToggleMenu(LibraryForPrincipalMenu);
//                     setSendingMenu(budgetJSON);
//                     setSelectedMenu("Budget");
//                     setKirdNm("");
//                 }
//                 else {
//                     setToggleMenu(PrincipalMenu);
//                     setSendingMenu(budgetJSON);
//                     setSelectedMenu("Budget");
//                     setKirdNm("");
//                 }
//             }
//             else if (authUser.roleId === 3) {
//                 setToggleMenu(ClerkMenu);
//                 setSendingMenu(budgetJSON);
//                 setSelectedMenu("Budget");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 7) {
//                 setToggleMenu(sansthaClerk);
//                 setSendingMenu(budgetSansthaJson);
//                 setSelectedMenu("Budget");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 8) {
//                 setToggleMenu(EEAccount);
//                 setSendingMenu(budgetJSON);
//                 setSelectedMenu("Budget");
//                 setKirdNm("");
//             }
//         }
//         else if (sessionMenuName === "Payroll") {
//             if (authUser.roleId === 1) {
//                 if (authUser.deptId === 3 || authUser.deptId === 4) {
//                     setToggleMenu(LibraryForPrincipalMenu);
//                     setSendingMenu(payrolAcademicJSON);
//                     setSelectedMenu("Payroll");
//                     setKirdNm("");
//                 }
//                 else {
//                     setToggleMenu(PrincipalMenu);
//                     setSendingMenu(payrolAcademicJSON);
//                     setSelectedMenu("Payroll");
//                     setKirdNm("");
//                 }
//             }
//             else if (authUser.roleId === 3) {
//                 setToggleMenu(ClerkMenu);
//                 setSendingMenu(payrolAcademicJSON);
//                 setSelectedMenu("Payroll");
//                 setKirdNm("");
//             }
//         }
//         else if (sessionMenuName === "Hostel") {
//             if (authUser.roleId === 1 || authUser.roleId === 3) {
//                 setToggleMenu(HostelMenu);
//                 setSendingMenu(HostelAcademicJSON);
//                 setSelectedMenu("Hostel");
//                 setKirdNm("");
//             }
//         }
//         else if (sessionMenuName === "Inventory Store") {
//             if (authUser.roleId === 1) {
//                 if (authUser.deptId === 3 || authUser.deptId === 4) {
//                     setToggleMenu(LibraryForPrincipalMenu);
//                     setSendingMenu(InventoryStoreJSON);
//                     setSelectedMenu("Inventory Store");
//                     setKirdNm("");
//                 }
//                 else {
//                     setToggleMenu(PrincipalMenu);
//                     setSendingMenu(InventoryStoreJSON);
//                     setSelectedMenu("Inventory Store");
//                     setKirdNm("");
//                 }
//             }
//             else if (authUser.roleId === 3) {
//                 setToggleMenu(ClerkMenu);
//                 setSendingMenu(InventoryStoreJSON);
//                 setSelectedMenu("Inventory Store");
//                 setKirdNm("");
//             }
//         }
//         else {
//             if (authUser.roleId === 3 && authUser.deptId === 5 && authUser.accApl === 0) {
//                 setToggleMenu(ClerkMenu);
//                 setSendingMenu(ClerkAcademicsJSON);
//                 setSelectedMenu("Academics");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 3 && authUser.deptId === 5) {
//                 setToggleMenu(dEdCollege);
//                 setSendingMenu(DedCollegeJSON);
//                 setSelectedMenu("Account");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 1) {
//                 if (authUser.deptId === 3 || authUser.deptId === 4) {
//                     setToggleMenu(LibraryForPrincipalMenu);
//                     setSendingMenu(PrinciaplAcademicsJSON);
//                     setSelectedMenu("Academics");
//                     setKirdNm("");
//                 }
//                 else if (authUser.deptId === 14) {
//                     setToggleMenu(HostelMenu);
//                     setSendingMenu(HostelAcademicJSON);
//                     setSelectedMenu("Academics");
//                     setKirdNm("");
//                 }
//                 else {
//                     setToggleMenu(PrincipalMenu);
//                     setSendingMenu(PrinciaplAcademicsJSON);
//                     setSelectedMenu("Academics");
//                     setKirdNm("");
//                 }
//             }
//             else if (authUser.roleId === 2) {
//                 setToggleMenu(SupervisorMenu);
//                 setSendingMenu(SupervisorAcademicsJSON);
//                 setSelectedMenu("Academics");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 3) {
//                 if (authUser.deptId === 14) {
//                     setToggleMenu(HostelMenu);
//                     setSendingMenu(HostelAcademicJSON);
//                     setSelectedMenu("Academics");
//                     setKirdNm("");
//                 } else {
//                     setToggleMenu(ClerkMenu);
//                     setSendingMenu(ClerkAcademicsJSON);
//                     setSelectedMenu("Academics");
//                     setKirdNm("");
//                 }
//             }
//             else if (authUser.roleId === 4) {
//                 setToggleMenu(TeacherMenu);
//                 setSendingMenu(TeacherAcademicsJSON);
//                 setSelectedMenu("Academics");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 5) {
//                 setToggleMenu(AdminMenu);
//                 setSendingMenu(TestingMenuId);
//                 setSelectedMenu("Master");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 6) {
//                 setToggleMenu(sansthaAcademic);
//                 setSendingMenu(SansthaAcademicJSON);
//                 setSelectedMenu("Sanstha");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 7) {
//                 setToggleMenu(sansthaClerk);
//                 setSendingMenu(SansthaAccountJSON);
//                 setSelectedMenu("Account");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 9) {
//                 setToggleMenu(LibraryMenu);
//                 setSendingMenu(LibraryAcademicsJSON);
//                 setSelectedMenu("Library");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 8) {
//                 setToggleMenu(EEAccount);
//                 setSendingMenu(EEAccountJSON);
//                 setSelectedMenu("Account");
//                 setKirdNm("");
//             }
//             else if (authUser.roleId === 10) {
//                 setToggleMenu(DengiMenu);
//                 setSendingMenu(DengiAccountJSON);
//                 setSelectedMenu("Account");
//                 setKirdNm("");
//             }
//         }
//     }, [authUser, num]);

//     const getMenuName = (ename) => {
//         setSelectedMenu(ename);
//         //console.log("role id : " + authUser.roleId + "ename : " + ename);
//         if (authUser.accApl === 1) {
//             if (authUser.roleId === 1) {
//                 if (authUser.deptId === 5 || authUser.deptId === 16 || authUser.deptId === 13) {
//                     if (ename === "Account") {
//                         setToggleMenu(dEdCollege);
//                         setSendingMenu(PrincipalAccountJSON);
//                         setSelectedMenu("Account");
//                         setKirdNm("");
//                         navigate("/Home/ac04/0");
//                     } else if (ename === "Budget") {
//                         setToggleMenu(dEdCollege);
//                         setSendingMenu(budgetJSON);
//                         setSelectedMenu("Budget");
//                         setKirdNm("");
//                         navigate("/Home/BudgetDashboard");
//                     }
//                 }
//                 else if (authUser.deptId === 14) {
//                     if (ename === "Account") {
//                         setToggleMenu(HostelMenu);
//                         setSendingMenu(PrincipalAccountJSON);
//                         setSelectedMenu("Account");
//                         setKirdNm("");
//                         navigate("/Home/ac04/0");
//                     }
//                     else if (ename === "Hostel") {
//                         setToggleMenu(HostelMenu);
//                         setSendingMenu(HostelAcademicJSON);
//                         setSelectedMenu("Hostel");
//                         setKirdNm("");
//                         navigate("/Home/HostelDashboard");
//                     }
//                     else if (ename === "Budget") {
//                         setToggleMenu(HostelMenu);
//                         setSendingMenu(budgetJSON);
//                         setSelectedMenu("Budget");
//                         setKirdNm("");
//                         navigate("/Home/BudgetDashboard");
//                     }
//                 }
//                 else {
//                     setToggleMenu(TermFee);
//                     setSendingMenu(PrincipalAccountJSON);
//                     setSelectedMenu("Account");
//                     setKirdNm("");
//                     navigate("/Home/ac04/0");
//                 }
//             }
//             else if (authUser.roleId === 3) {
//                 if (authUser.deptId === 16 || authUser.deptId === 13) {
//                     if (ename === "Account") {
//                         setToggleMenu(dEdCollege);
//                         setSendingMenu(ClerkAccountJSON);
//                         setSelectedMenu("Account");
//                         setKirdNm("");
//                         navigate("/Home/ac04/0");
//                     }
//                     else if (ename === "Budget") {
//                         setToggleMenu(dEdCollege);
//                         setSendingMenu(budgetJSON);
//                         setSelectedMenu("Budget");
//                         setKirdNm("");
//                         navigate("/Home/BudgetDashboard");
//                     }
//                 }
//                 else if (authUser.deptId === 14) {
//                     if (ename === "Account") {
//                         setToggleMenu(HostelMenu);
//                         setSendingMenu(ClerkAccountJSON);
//                         setSelectedMenu("Account");
//                         setKirdNm("");
//                         navigate("/Home/ac04/0");
//                     }
//                     else if (ename === "Hostel") {
//                         setToggleMenu(HostelMenu);
//                         setSendingMenu(HostelAcademicJSON);
//                         setSelectedMenu("Hostel");
//                         setKirdNm("");
//                         navigate("/Home/HostelDashboard");
//                     }
//                     else if (ename === "Budget") {
//                         setToggleMenu(HostelMenu);
//                         setSendingMenu(budgetJSON);
//                         setSelectedMenu("Budget");
//                         setKirdNm("");
//                         navigate("/Home/BudgetDashboard");
//                     }
//                 }
//                 else if (authUser.deptId === 5) {
//                     if (ename === "Account") {
//                         setToggleMenu(dEdCollege);
//                         setSendingMenu(DedCollegeJSON);
//                         setSelectedMenu("Account");
//                         setKirdNm("");
//                         navigate("/Home/ac04/0");
//                     }
//                     else if (ename === "Budget") {
//                         setToggleMenu(dEdCollege);
//                         setSendingMenu(budgetJSON);
//                         setSelectedMenu("Budget");
//                         setKirdNm("");
//                         navigate("/Home/BudgetDashboard");
//                     }
//                 }
//                 else {
//                     setToggleMenu(TermFee);
//                     setSendingMenu(ClerkAccountJSON);
//                     setSelectedMenu("Account");
//                     setKirdNm("");
//                     navigate("/Home/ac04/0");
//                 }
//             }
//             else if (authUser.roleId === 5) {
//                 setToggleMenu(TermFee);
//                 setSendingMenu(TestingMenuId);
//                 setSelectedMenu("Account");
//                 setKirdNm("");
//                 navigate("/Home/ac04/0");
//             }
//             else if (authUser.roleId === 7) {
//                 if (ename === "Account") {
//                     setToggleMenu(sansthaClerk);
//                     setSendingMenu(SansthaAccountJSON);
//                     setSelectedMenu("Account");
//                     setKirdNm("");
//                     navigate("/Home/ac04/0");
//                 }
//                 else if (ename === "Budget") {
//                     setToggleMenu(sansthaClerk);
//                     setSendingMenu(budgetSansthaJson);
//                     setSelectedMenu("Budget");
//                     setKirdNm("");
//                     navigate("/Home/BudgetDashboard");
//                 }
//             }
//             else if (authUser.roleId === 8) {
//                 if (ename === "Account") {
//                     setToggleMenu(EEAccount);
//                     setSendingMenu(EEAccountJSON);
//                     setSelectedMenu("Account");
//                     setKirdNm("");
//                     navigate("/Home/ac04/0");
//                 }
//                 else if (ename === "Budget") {
//                     setToggleMenu(EEAccount);
//                     setSendingMenu(budgetJSON);
//                     setSelectedMenu("Budget");
//                     setKirdNm("");
//                     navigate("/Home/BudgetDashboard");
//                 }
//             }
//             else if (authUser.roleId === 10) {
//                 if (ename === "Account") {
//                     setToggleMenu(DengiMenu);
//                     setSendingMenu(DengiAccountJSON);
//                     setSelectedMenu("Account");
//                     setKirdNm("");
//                     navigate("/Home/f314/0");
//                 }
//             }
//         }
//         else {
//             if ((authUser.roleId === 3 && authUser.deptId === 5)) {
//                 if (ename === "Budget" && authUser.accApl === 0) {
//                     setToggleMenu(ClerkMenu);
//                     setSendingMenu(ClerkAccountJSON);
//                     setKirdNm("");
//                     navigate("/Home/BudgetDashboard");
//                 } else if (ename === "Budget") {
//                     setToggleMenu(dEdCollege);
//                     setSendingMenu(budgetJSON);
//                     setKirdNm("");
//                     navigate("/Home/BudgetDashboard");
//                 } else if (ename === "Academic" && authUser.accApl === 0) {
//                     setToggleMenu(ClerkMenu);
//                     setSendingMenu(ClerkAcademicsJSON);
//                     setKirdNm("");
//                     navigate("/Home/dashboard");
//                 } else if (ename === "Account" && authUser.accApl === 0) {
//                     setToggleMenu(ClerkMenu);
//                     setSendingMenu(ClerkAccountJSON);
//                     setKirdNm(" (शाळा किर्द.)");
//                     navigate("/Home/ac04/0");
//                 }
//                 else if (ename === "Account") {
//                     setToggleMenu(dEdCollege);
//                     setSendingMenu(DedCollegeJSON);
//                     setKirdNm(" (शाळा किर्द.)");
//                     navigate("/Home/ac04/0");
//                 }

//             }
//             else if (authUser.roleId === 1) {
//                 if (authUser.deptId == 3 || authUser.deptId == 4) {
//                     if (ename === "Academic") {
//                         setToggleMenu(LibraryForPrincipalMenu);
//                         setSendingMenu(PrinciaplAcademicsJSON);
//                         setKirdNm("");
//                         navigate("/Home/dashboard");
//                     }
//                     else if (ename === "Account") {
//                         setToggleMenu(LibraryForPrincipalMenu);
//                         setSendingMenu(PrincipalAccountJSON);
//                         setKirdNm(" (शाळा किर्द.)");
//                         navigate("/Home/ac04/0");
//                     }
//                     else if (ename === "Library") {
//                         setToggleMenu(LibraryForPrincipalMenu);
//                         setSendingMenu(LibraryAcademicsJSON);
//                         setKirdNm("");
//                         navigate("/Home/LibraryDashboard");
//                     }
//                     else if (ename === "Mobile App") {
//                         setToggleMenu(LibraryForPrincipalMenu);
//                         setSendingMenu(mobileApp);
//                         setKirdNm("");
//                         navigate("/Home/ma1001");
//                     }
//                     else if (ename === "Budget") {
//                         setToggleMenu(LibraryForPrincipalMenu);
//                         setSendingMenu(budgetJSON);
//                         setKirdNm("");
//                         navigate("/Home/BudgetDashboard");
//                     }
//                     else if (ename === "Payroll") {
//                         setToggleMenu(LibraryForPrincipalMenu);
//                         setSendingMenu(payrolAcademicJSON);
//                         navigate("/Home/payrollDashboard");
//                         setKirdNm("");
//                     }
//                     else if (ename === "Inventory Store") {
//                         setToggleMenu(LibraryForPrincipalMenu);
//                         setSendingMenu(InventoryStoreJSON);
//                         navigate("/Home/StoreDashboard");
//                         setKirdNm("");
//                     }
//                 }
//                 else {
//                     if (ename === "Academic") {
//                         setToggleMenu(PrincipalMenu);
//                         setSendingMenu(PrinciaplAcademicsJSON);
//                         setKirdNm("");
//                         navigate("/Home/dashboard");
//                     }
//                     else if (ename === "Account") {
//                         setToggleMenu(PrincipalMenu);
//                         setSendingMenu(PrincipalAccountJSON);
//                         setKirdNm(" (शाळा किर्द.)");
//                         navigate("/Home/ac04/0");
//                     }
//                     else if (ename === "Mobile App") {
//                         setToggleMenu(PrincipalMenu);
//                         setSendingMenu(mobileApp);
//                         setKirdNm("");
//                         navigate("/Home/ma1001");
//                     }
//                     else if (ename === "Budget") {
//                         setToggleMenu(PrincipalMenu);
//                         setSendingMenu(budgetJSON);
//                         setKirdNm("");
//                         navigate("/Home/BudgetDashboard");
//                     }
//                     else if (ename === "Payroll") {
//                         setToggleMenu(PrincipalMenu);
//                         setSendingMenu(payrolAcademicJSON);
//                         setKirdNm("");
//                         navigate("/Home/payrollDashboard");
//                     }
//                     else if (ename === "Inventory Store") {
//                         setToggleMenu(PrincipalMenu);
//                         setSendingMenu(InventoryStoreJSON);
//                         navigate("/Home/StoreDashboard");
//                         setKirdNm("");
//                     }
//                 }
//             }
//             else if (authUser.roleId === 2) {
//                 if (ename === "Academic") {
//                     setToggleMenu(SupervisorMenu);
//                     setSendingMenu(SupervisorAcademicsJSON);
//                     setKirdNm("");
//                     navigate("/Home/dashboard");
//                 }
//             }
//             else if (authUser.roleId === 3) {
//                 if (ename === "Academic") {
//                     setToggleMenu(ClerkMenu);
//                     setSendingMenu(ClerkAcademicsJSON);
//                     setKirdNm("");
//                     navigate("/Home/dashboard");
//                 }
//                 else if (ename === "Account") {
//                     setToggleMenu(ClerkMenu);
//                     setSendingMenu(ClerkAccountJSON);
//                     setKirdNm(" (शाळा किर्द.)");
//                     navigate("/Home/ac04/0");
//                 }
//                 else if (ename === "Mobile App") {
//                     setToggleMenu(ClerkMenu);
//                     setSendingMenu(mobileApp);
//                     setKirdNm("");
//                     navigate("/Home/ma1001");
//                 }
//                 else if (ename === "Budget") {
//                     setToggleMenu(ClerkMenu);
//                     setSendingMenu(budgetJSON);
//                     setKirdNm("");
//                     navigate("/Home/BudgetDashboard");
//                 }
//                 else if (ename === "Payroll") {
//                     setToggleMenu(ClerkMenu);
//                     setSendingMenu(payrolAcademicJSON);
//                     setKirdNm("");
//                     navigate("/Home/payrollDashboard");
//                 }
//                 else if (ename === "Inventory Store") {
//                     setToggleMenu(ClerkMenu);
//                     setSendingMenu(InventoryStoreJSON);
//                     setKirdNm("");
//                     navigate("/Home/StoreDashboard");
//                 }
//             }
//             else if (authUser.roleId === 4) {
//                 if (ename === "Academic") {
//                     setToggleMenu(TeacherMenu);
//                     setSendingMenu(TeacherAcademicsJSON);
//                     setKirdNm("");
//                     navigate("/Home/dashboard");
//                 }
//             }
//             else if (authUser.roleId === 5) {
//                 setToggleMenu(AdminMenu);
//                 setSendingMenu(TestingMenuId);
//                 setKirdNm("");
//                 navigate("/Home/dashboard");

//             }
//             else if (authUser.roleId === 6) {
//                 if (ename === "Sanstha") {
//                     setToggleMenu(sansthaAcademic);
//                     setSendingMenu(SansthaAcademicJSON);
//                     setKirdNm("");
//                     navigate("/Home/dashboard");
//                 }
//                 else if (ename === "Endowment") {
//                     setToggleMenu(sansthaAcademic);
//                     setSendingMenu(SansthaEndowmentJSON);
//                     setKirdNm("");
//                     navigate("/Home/EndowmentDash/");
//                 }
//             }
//             else if (authUser.roleId === 7) {
//                 if (ename === "Account") {
//                     setToggleMenu(sansthaClerk);
//                     setSendingMenu(SansthaAccountJSON);
//                     setKirdNm("");
//                     navigate("/Home/ac04/0");
//                 }
//                 else if (ename === "Budget") {
//                     setToggleMenu(sansthaClerk);
//                     setSendingMenu(budgetSansthaJson);
//                     setKirdNm("");
//                     navigate("/Home/BudgetDashboard");
//                 }
//             }
//             else if (authUser.roleId === 9) {
//                 if (ename === "Library") {
//                     setToggleMenu(LibraryMenu);
//                     setSendingMenu(LibraryAcademicsJSON);
//                     setKirdNm("");
//                     navigate("/Home/LibraryDashboard");
//                 }
//             }
//             else if (authUser.roleId === 8) {
//                 if (ename === "Account") {
//                     setToggleMenu(EEAccount);
//                     setSendingMenu(EEAccountJSON);
//                     setKirdNm("");
//                     navigate("/Home/ac04/0");
//                 }
//                 else if (ename === "Budget") {
//                     setToggleMenu(EEAccount);
//                     setSendingMenu(budgetJSON);
//                     setKirdNm("");
//                     navigate("/Home/BudgetDashboard");
//                 }
//             }
//             else {
//                 console.log("error-" + authUser.roleId + "-")
//             }
//         }
//     }

//     const refreshMenu = () => {
//         document.getElementById("tbtn").click({});
//         setSendingMenu([]);
//         setNum(num + 1);
//     }


//     return (
//         <>
//             <nav className="navbar horizontal-layout col-lg-12 col-12 p-0">
//                 <div className='container d-flex flex-row'>
//                     <div className='col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xs-12 mt-2'>
//                         <div className='row'>
//                             <table style={{ padding: "0px" }}>
//                                 <thead>
//                                     <tr>
//                                         <td rowSpan="2" style={{ width: "80px" }}>
//                                             <img src={logoMini} alt="logo" id="logo" style={{ width: "70px" }} />
//                                             {/* <img src={demoSchoolLogMini} alt="logo" id="logo" style={{ width: "70px" }} /> */}
//                                         </td>
//                                         <td style={{ verticalAlign: "bottom", fontSize: "18px", fontWeight: "600" }}>{authUser.branchName}</td>
//                                     </tr>
//                                     <tr>
//                                         <td style={{ fontSize: "14px", fontWeight: "700", color: "#F39C12" }}> <span style={{ color: "#BE0505" }}>{authUser.deptName}</span> <span style={{ color: "#5E50F9" }}>{kirdNm}</span> <span style={{ color: "#290101" }}>|</span> {authUser.yearLabel}</td>
//                                     </tr>
//                                 </thead>
//                             </table>

//                         </div>
//                     </div>

//                     <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xs-12' style={{ textAlign: "right" }}>
//                         {/* <!-- Example single danger button --> */}
//                         <div className="btn-group">
//                             <button type="button" className="btn btn-sm dropdown-toggle p-0 m-0 p-1" data-bs-toggle="dropdown" aria-expanded="false">
//                                 <WidgetsIcon fontSize="small" style={{ color: "#464de4" }} /> {selectedMenu}
//                             </button>
//                             <ul className="dropdown-menu">
//                                 {
//                                     toggleMenu.map((e, index) => {
//                                         //console.log(JSON.stringify(e))
//                                         return (
//                                             <li key={index} style={{ cursor: "pointer" }}>
//                                                 <span className="dropdown-item small"
//                                                     onClick={() => {
//                                                         getMenuName(e.name);
//                                                         setSelectedMenu(e.name);
//                                                         sessionStorage.setItem('menuName', e.name);
//                                                     }}
//                                                 >
//                                                     {e.name}
//                                                 </span>
//                                             </li>
//                                         )
//                                     })
//                                 }
//                             </ul>
//                         </div>

//                         <div className="btn-group ml-2">
//                             <button type="button" id="toggle-btn" className="btn btn-sm dropdown-toggle p-0 m-0 p-1" data-bs-toggle="dropdown" aria-expanded="false">
//                                 <AccountCircleIcon fontSize="small" style={{ color: "#464de4" }} /> {authUser.roleName}
//                             </button>
//                             <ul className="dropdown-menu p-0 m-0 py-1">
//                                 <li><span className="dropdown-item small p-0 m-0 pl-2 my-1" style={{ cursor: "pointer" }} ><LockIcon style={{ color: "#464de4", fontSize: "20px" }} /> Change Password</span></li>
//                                 <li><span className="dropdown-item small p-0 m-0 pl-2 my-1" style={{ cursor: "pointer" }} onClick={logoutApp}><ExitToAppIcon style={{ color: "#464de4", fontSize: "20px" }} /> Logout</span></li>
//                             </ul>
//                         </div>

//                         <button type="button" className="btn btn-light position-relative p-1 ml-2" onClick={() => navigate("/Home/s618/")}>
//                             <NotificationsActiveIcon fontSize="small" className='text-primary' />
//                             <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
//                                 {notificationCount}
//                             </span>
//                         </button>
//                     </div>
//                 </div>
//                 <div className="container d-flex flex-row">
//                     <div className="navbar-menu-wrapper d-flex align-items-left">
//                         <span id="tbtn" data-toggle="minimize" className="navbar-toggler align-self-center" style={{ color: "black", padding: "0px", cursor: "pointer" }} >
//                             <MenuIcon fontSize="large" />
//                         </span>
//                     </div>
//                 </div>
//                 <div className="nav-bottom">
//                     <div className="container">
//                         <TestMenu sendingMenu={sendingMenu} refreshMenu={refreshMenu} showModal={showModal} setShowModal={setShowModal} authUser={authUser} />
//                     </div>
//                 </div>
//             </nav>

//             {
//                 (systemMessageData.length > 0)
//                     ?
//                     (systemMessageData[0].status == 1) ?
//                         <div style={{ backgroundColor: "#E01D1D", color: "#fff", fontWeight: 600, padding: 0, margin: 0 }}>
//                             <marquee behavior="scroll" direction="left" onmouseover="this.stop();" onmouseout="this.start();">
//                                 {systemMessageData[0].remark}
//                             </marquee>
//                         </div>
//                         :
//                         null
//                     :
//                     null
//             }

//             <SansthaAdminModal showModal={showModal} setShowModal={setShowModal} sendingMenu={sendingMenu} />
//         </>
//     )
// }

// export default MasterHeader;



import React, { useState, useEffect } from 'react';
import '../../vendors/horizontal-layout/style.css';
// import logoMini from './logo-mini.svg';
import logoMini from './logo-mini-palus.svg';
// import logoMini from './logo-mini-les.svg';
// import logoMini from './logo-mini-anglo.svg';
// import logoMini from './Purna-School-Logo.svg';
// import logoMini from './logo-turchi.svg';
// import logoMini from './logo-mini-bsb.svg';
// import logoMini from './logo-mini-mtes.svg';
// import logoMini from './logo-mini-pvdpssp.svg';
// import logoMini from './logo-mini-narnde.svg';
// import logoMini from './logo-mini-jamsande.svg';
// import logoMini from './logo-mini-shirgaon.svg';
// import logoMini from './logo-mini-devgad.svg';
// import logoMini from './logo-mini-shirdhon.svg';
// import logoMini from './logo-mini-padel.svg';
// import logoMini from './logo-mini-sankh.svg';


import demoSchoolLogMini from '../Login/Purna-School-Logo.png'
import TestMenu from './TestMenu';
import { useSelector } from "react-redux";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import axios from 'axios';
import MenuIcon from '@mui/icons-material/Menu';
import WidgetsIcon from '@mui/icons-material/Widgets';
import LockIcon from '@mui/icons-material/Lock';
import { login, logout, accLogOut } from '../../reduxFeature/user';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router";
import {
    PrincipalMenu, ClerkMenu, SupervisorMenu, TeacherMenu, AdminMenu, TermFee, sansthaAcademic,
    sansthaClerk, dEdCollege, EEAccount, LibraryMenu, LibraryForPrincipalMenu, HostelMenu, DengiMenu, onlyHostelMenu, onlyLibraryMenu
} from "../Menu/Service/ToggleMenu";
// Principal Menu imported
import PrinciaplAcademicsJSON from "./Service/PrinciaplAcademicsJSON";
import PrincipalAccountJSON from "./Service/PrincipalAccountJSON";
// Clerk Menu imported
import ClerkAccountJSON from "./Service/ClerkAccountJSON";
import ClerkAcademicsJSON from "./Service/ClerkAcademicsJSON";
// Supervisor Menu imported
import SupervisorAcademicsJSON from "./Service/SupervisorAcademicsJSON";
// Teacher Menu imported
import TeacherAcademicsJSON from "./Service/TeacherAcademicsJSON";
//Admin Menu Imported
import TestingMenuId from "./Service/TestingMenuId";

import LibraryAcademicsJSON from './Service/LibraryAcademicJSON';

import mobileApp from './Service/MobileAppWorkingJSON';

// Sanstha Menu Imported
import SansthaAcademicJSON from "./Service/SansthaAcademicJSON";
import SansthaAccountJSON from "./Service/SansthaAccountJSON";

import SansthaEndowmentJSON from './Service/SansthaEndowmentJSON';

import DedCollegeJSON from "./Service/DedCollegeJSON";

import EEAccountJSON from './Service/EEAccountJson';

import DengiAccountJSON from './Service/DengiAccountJSON';
//notification APIURL
import { getServiceFormUserFlagDataCountAPIURL } from '../../ServicePanel/Service/allEnquiryAPIURL';

import { getNoticeDataAPIURL } from '../../ServicePanel/Service/SystemUpdateAPIURL';
import SansthaAdminModal from '../../Sanstha/SansthaAccount/SansthaAdminModal';
import budgetJSON from './Service/BudgetAcademicJSON';
import budgetSansthaJson from './Service/BudgetSansthaJSON';
import payrolAcademicJSON from './Service/PayrolAcademicJSON';
import HostelAcademicJSON from './Service/HostelAcademicJSON';
import InventoryStoreJSON from './Service/InventoryStoreJSON';
import { getEmployeeBdayListAPIURL, getStudentsBdayListAPIURL } from '../../CommonServices/DashboardAPIURL';
import { faBirthdayCake } from '@fortawesome/free-solid-svg-icons';
import './Marquee.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CakeIcon from '@mui/icons-material/Cake';
import { getFormDataByRoleAndBranch } from '../../CommonServices/LoginAPIURL';

const resp1 = [
    {
        "classEn": "10th",
        "divMr": "क ",
        "regNo": 12645,
        "classMr": "10 वी ",
        "divEn": "C",
        "studentNameMr": "चौगुले रोहन महेश",
        "studentNameEn": "  "
    },
    {
        "classEn": "10th",
        "divMr": "क ",
        "regNo": 12645,
        "classMr": "10 वी ",
        "divEn": "C",
        "studentNameMr": "चौगुले रोहन महेश",
        "studentNameEn": "  "
    },
    {
        "classEn": "10th",
        "divMr": "क ",
        "regNo": 12645,
        "classMr": "10 वी ",
        "divEn": "C",
        "studentNameMr": "चौगुले रोहन महेश",
        "studentNameEn": "  "
    },
]

const resp2 = [
    {
        "empId": 353,
        "empNameMr": "कदम प्रकाश विश्वनाथ",
        "empNameEn": "Kadam Prakash Vishvanath"
    },
    {
        "empId": 353,
        "empNameMr": "कदम प्रकाश विश्वनाथ",
        "empNameEn": "Kadam Prakash Vishvanath"
    },
    {
        "empId": 353,
        "empNameMr": "कदम प्रकाश विश्वनाथ",
        "empNameEn": "Kadam Prakash Vishvanath"
    },
]


const MasterHeader = () => {
    const authUser = useSelector((state) => state.user.value);
    // console.log(authUser.feeType)


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isMessage, setIsMessage] = useState(0)
    //console.log(isMessage);

    const [notificationCount, setNotificationCount] = useState(0)
    const [systemMessageData, setSystemMessageData] = useState([])

    const [showModal, setShowModal] = useState(false);

    const logoutApp = () => {
        sessionStorage.removeItem("inst-sss-d");
        sessionStorage.removeItem("menuName");
        sessionStorage.removeItem('FormProcessingFlag');
        dispatch(logout());
        navigate("/");
    }

    //Auto Logout after 15 min Start    

    // var timer;
    // const autoLogout = () => {
    //     sessionStorage.removeItem("inst-sss-d");
    //     sessionStorage.removeItem("menuName");
    //     dispatch(logout());
    //     navigate("/");        
    // }

    // const resetTimer = () => {  
    //     timer=0;      
    //     clearTimeout(timer);
    //     console.log(timer);
    //     timer = setTimeout(autoLogout, 5000);
    // }

    // document.onmousemove =()=>{
    //     resetTimer();
    // }
    // document.onkeydown =()=>{
    //     resetTimer();
    // }
    // document.onkeyup =()=>{
    //     resetTimer();
    // }


    //Auto Logout after 15 min End


    const getNotificationCount = async () => {
        await axios.get(`${getServiceFormUserFlagDataCountAPIURL}?roleId=${authUser.roleId}&bid=${authUser.branchId}&sid=${authUser.deptId}`)
            .then((res) => {
                if (res.data !== null) {
                    //console.log(res.data)
                    setNotificationCount(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getSystemMessage = async () => {
        await axios.get(getNoticeDataAPIURL)
            .then((res) => {
                if (res.data !== null) {
                    setSystemMessageData(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (authUser.roleId !== null && authUser.branchId !== null && authUser.deptId !== null) {
            getNotificationCount();
            getSystemMessage();
        }
    }, [authUser])

    //Auto Logout after 15 min Start    

    const [counter, setCounter] = useState(0)

    var timer;
    const autoNotificationCall = () => {
        setCounter(counter + 1);
        getNotificationCount();
        getSystemMessage();
    }

    const resetTimer = () => {
        timer = 0;
        clearTimeout(timer);
        //console.log(timer);
        timer = setTimeout(autoNotificationCall, 6000);
    }

    useEffect(() => {
        if (authUser.roleId !== null && authUser.branchId !== null && authUser.deptId !== null) {
            resetTimer();
        }
    }, [counter, authUser])

    //Auto Logout after 15 min End

    const [selectedMenu, setSelectedMenu] = useState("Menu");
    const [sendingMenu, setSendingMenu] = useState([]);

    // console.log(JSON.stringify(sendingMenu))

    const [toggleMenu, setToggleMenu] = useState([{}]);

    const [num, setNum] = useState(1);

    const [kirdNm, setKirdNm] = useState("");

    const [studBdayData, setStudBdayData] = useState([]);
    const [empBdayData, setEmpBdayData] = useState([]);

    // console.log('toggle menu==' + toggleMenu + "sendingMenu===" + sendingMenu)

    useEffect(() => {
        getStudEmpBdayList();
    }, [authUser])


    const getStudEmpBdayList = async () => {
        if (authUser.branchId != null && authUser.deptId != null && authUser.sessionYear != "") {
            await axios(`${getStudentsBdayListAPIURL}?acyr=${authUser.sessionYear}&sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setStudBdayData(res.data);
                    // console.log(JSON.stringify(res.data))
                })

            await axios(`${getEmployeeBdayListAPIURL}?sid=${authUser.deptId}&bid=${authUser.branchId}`)
                .then(res => {
                    setEmpBdayData(res.data);
                })
        }
    }


    useEffect(() => {
        const sessionMenuName = sessionStorage.getItem("menuName");
        //console.log("role id : " + authUser.roleId+"dept id : " + authUser.deptId);
        //console.log(sessionMenuName);
        if (authUser.accApl === 1) {
            if ((authUser.roleId === 3 && authUser.deptId === 5)) {
                if (sessionMenuName === "Budget") {
                    setToggleMenu(dEdCollege);
                    setSendingMenu(budgetJSON);
                    setSelectedMenu("Budget");
                    setKirdNm("");
                }
                else {
                    setToggleMenu(dEdCollege);
                    setSendingMenu(DedCollegeJSON);
                    setSelectedMenu("Account");
                    setKirdNm("");
                }

            }
            else if (authUser.roleId === 1) {
                if (authUser.deptId === 5 || authUser.deptId === 16 || authUser.deptId === 13) {
                    if (sessionMenuName === "Budget") {
                        setToggleMenu(dEdCollege);
                        setSendingMenu(budgetJSON);
                        setSelectedMenu("Budget");
                        setKirdNm("");
                    }
                    else {
                        setToggleMenu(dEdCollege);
                        setSendingMenu(PrincipalAccountJSON);
                        setSelectedMenu("Account");
                        setKirdNm("");
                    }
                }
                else if (authUser.deptId === 14) {
                    if (sessionMenuName === "Budget") {
                        setToggleMenu(HostelMenu);
                        setSendingMenu(budgetJSON);
                        setSelectedMenu("Budget");
                        setKirdNm("");
                    }
                    else if (sessionMenuName === "Hostel") {
                        setToggleMenu(HostelMenu);
                        setSendingMenu(HostelAcademicJSON);
                        setSelectedMenu("Hostel");
                        setKirdNm("");
                    }
                    else {
                        setToggleMenu(HostelMenu);
                        setSendingMenu(PrincipalAccountJSON);
                        setSelectedMenu("Account");
                        setKirdNm("");
                    }
                }
                else {
                    setToggleMenu(TermFee);
                    setSendingMenu(PrincipalAccountJSON);
                    setSelectedMenu("Account");
                    setKirdNm("");
                }
            }
            else if (authUser.roleId === 3) {
                if (authUser.deptId === 16 || authUser.deptId === 13) {
                    if (sessionMenuName === "Budget") {
                        setToggleMenu(dEdCollege);
                        setSendingMenu(budgetJSON);
                        setSelectedMenu("Budget");
                        setKirdNm("");
                    }
                    else {
                        setToggleMenu(dEdCollege);
                        setSendingMenu(ClerkAccountJSON);
                        setSelectedMenu("Account");
                        setKirdNm("");
                    }
                }
                else if (authUser.deptId === 14) {
                    if (sessionMenuName === "Budget") {
                        setToggleMenu(HostelMenu);
                        setSendingMenu(budgetJSON);
                        setSelectedMenu("Budget");
                        setKirdNm("");
                    }
                    else if (sessionMenuName === "Hostel") {
                        setToggleMenu(HostelMenu);
                        setSendingMenu(HostelAcademicJSON);
                        setSelectedMenu("Hostel");
                        setKirdNm("");
                    }
                    else {
                        setToggleMenu(HostelMenu);
                        setSendingMenu(PrincipalAccountJSON);
                        setSelectedMenu("Account");
                        setKirdNm("");
                    }
                }
                else if (authUser.deptId === 5) {
                    if (sessionMenuName === "Account") {
                        setToggleMenu(dEdCollege);
                        setSendingMenu(DedCollegeJSON);
                        setSelectedMenu("Account");
                        setKirdNm("");
                        navigate("/Home/ac04/0");
                    }
                    else if (sessionMenuName === "Budget") {
                        setToggleMenu(dEdCollege);
                        setSendingMenu(budgetJSON);
                        setSelectedMenu("Budget");
                        setKirdNm("");
                        navigate("/Home/BudgetDashboard");
                    }
                }
                else {
                    setToggleMenu(TermFee);
                    setSendingMenu(ClerkAccountJSON);
                    setSelectedMenu("Account");
                    setKirdNm("");
                }
            }
            else if (authUser.roleId === 5) {
                setToggleMenu(TermFee);
                setSendingMenu(TestingMenuId);
                setSelectedMenu("Account");
                setKirdNm("");
            }
            else if (authUser.roleId === 7) {
                if (sessionMenuName === "Budget") {
                    setToggleMenu(sansthaClerk);
                    setSendingMenu(budgetSansthaJson);
                    setSelectedMenu("Budget");
                    setKirdNm("");
                }
                else {
                    setToggleMenu(sansthaClerk);
                    setSendingMenu(SansthaAccountJSON);
                    setSelectedMenu("Account");
                    setKirdNm("");
                }
            }
            else if (authUser.roleId === 8) {
                if (sessionMenuName === "Budget") {
                    setToggleMenu(EEAccount);
                    setSendingMenu(budgetJSON);
                    setSelectedMenu("Budget");
                    setKirdNm("");
                }
                else {
                    setToggleMenu(EEAccount);
                    setSendingMenu(EEAccountJSON);
                    setSelectedMenu("Account");
                    setKirdNm("");
                }
            }
            else if (authUser.roleId === 10) {
                setToggleMenu(DengiMenu);
                setSendingMenu(DengiAccountJSON);
                setSelectedMenu("Account");
                setKirdNm("");
            }

        }
        else if (sessionMenuName === "Academics") {
            if (authUser.roleId === 1) {
                if (authUser.deptId === 3 || authUser.deptId === 4) {
                    setToggleMenu(LibraryForPrincipalMenu);
                    setSendingMenu(PrinciaplAcademicsJSON);
                    setSelectedMenu("Academics");
                    setKirdNm("");
                }
                else {
                    setToggleMenu(PrincipalMenu);
                    setSendingMenu(PrinciaplAcademicsJSON);
                    setSelectedMenu("Academics");
                    setKirdNm("");
                }
            }
            else if (authUser.roleId === 2) {
                setToggleMenu(SupervisorMenu);
                setSendingMenu(SupervisorAcademicsJSON);
                setSelectedMenu("Academics");
                setKirdNm("");
            }
            else if (authUser.roleId === 3) {
                setToggleMenu(ClerkMenu);
                setSendingMenu(ClerkAcademicsJSON);
                setSelectedMenu("Academics");
                setKirdNm("");
            }
            else if (authUser.roleId === 4) {
                setToggleMenu(TeacherMenu);
                setSendingMenu(TeacherAcademicsJSON);
                setSelectedMenu("Academics");
                setKirdNm("");
            }
            else if (authUser.roleId === 5) {
                setToggleMenu(AdminMenu);
                setSendingMenu(TestingMenuId);
                setSelectedMenu("Master");
                setKirdNm("");
            }
            else if (authUser.roleId === 6) {
                setToggleMenu(sansthaAcademic);
                setSendingMenu(SansthaAcademicJSON);
                setSelectedMenu("Sanstha");
                setKirdNm("");
            }
            else if (authUser.roleId === 11) {
                setToggleMenu(onlyHostelMenu);
                setSendingMenu(HostelAcademicJSON);
                setSelectedMenu("Hostel");
                setKirdNm("");
            }
            else if (authUser.roleId === 12) {
                setToggleMenu(onlyLibraryMenu);
                setSendingMenu(LibraryAcademicsJSON);
                setSelectedMenu("Library");
                setKirdNm("");
            }
        }
        else if (sessionMenuName === "Endowment") {
            if (authUser.roleId === 6) {
                setToggleMenu(sansthaAcademic);
                setSendingMenu(SansthaEndowmentJSON);
                setSelectedMenu("Endowment");
                setKirdNm("");
            }
        }
        else if (sessionMenuName === "Account") {
            if (authUser.roleId === 3 && authUser.deptId === 5 && authUser.accApl === 0) {
                setToggleMenu(ClerkMenu);
                setSendingMenu(ClerkAccountJSON);
                setSelectedMenu("Account");
                setKirdNm(" (शाळा किर्द.)");
            } else if (authUser.roleId === 3 && authUser.deptId === 5) {
                setToggleMenu(dEdCollege);
                setSendingMenu(DedCollegeJSON);
                setSelectedMenu("Account");
                setKirdNm("");
            }
            else if (authUser.roleId === 1) {
                if (authUser.deptId === 3 || authUser.deptId === 4) {
                    setToggleMenu(LibraryForPrincipalMenu);
                    setSendingMenu(PrincipalAccountJSON);
                    setSelectedMenu("Account");
                    setKirdNm(" (शाळा किर्द.)");
                }
                else {
                    setToggleMenu(PrincipalMenu);
                    setSendingMenu(PrincipalAccountJSON);
                    setSelectedMenu("Account");
                    setKirdNm(" (शाळा किर्द.)");
                }

            }
            else if (authUser.roleId === 3) {
                setToggleMenu(ClerkMenu);
                setSendingMenu(ClerkAccountJSON);
                setSelectedMenu("Account");
                setKirdNm(" (शाळा किर्द.)");
                //navigate("/Home/ac04/0");
            }
            else if (authUser.roleId === 5) {
                setToggleMenu(AdminMenu);
                setSendingMenu(TestingMenuId);
                setSelectedMenu("Account");
                setKirdNm(" (शाळा किर्द.)");

            }
            else if (authUser.roleId === 7) {
                setToggleMenu(sansthaClerk);
                setSendingMenu(SansthaAccountJSON);
                setSelectedMenu("Account");
                setKirdNm(" (शाळा किर्द.)");

            }
            else if (authUser.roleId === 8) {
                setToggleMenu(EEAccount);
                setSendingMenu(EEAccountJSON);
                setSelectedMenu("Account");
                setKirdNm("");
            }
        }
        else if (sessionMenuName === "Library") {
            if (authUser.roleId === 12) {
                setToggleMenu(onlyLibraryMenu);
                setSendingMenu(LibraryAcademicsJSON);
                setSelectedMenu("Library");
                setKirdNm("");
            }
            else if (authUser.roleId == 1) {
                if (authUser.deptId === 3 || authUser.deptId === 4) {
                    setToggleMenu(LibraryForPrincipalMenu);
                    setSendingMenu(LibraryAcademicsJSON);
                    setSelectedMenu("Library");
                    setKirdNm("");
                }
            }
            else if (authUser.roleId === 9) {
                setToggleMenu(LibraryMenu);
                setSendingMenu(LibraryAcademicsJSON);
                setSelectedMenu("Library");
                setKirdNm("");
            }

        }
        else if (sessionMenuName === "Mobile App") {
            if (authUser.roleId == 1) {
                setToggleMenu(LibraryForPrincipalMenu);
                setSendingMenu(mobileApp);
                setSelectedMenu("Mobile App");
                setKirdNm("");
            }
            else if (authUser.roleId === 3) {
                setToggleMenu(ClerkMenu);
                setSendingMenu(mobileApp);
                setSelectedMenu("Mobile App");
                setKirdNm("");
            }
        }
        else if (sessionMenuName === "Budget") {
            if (authUser.roleId === 1) {
                if (authUser.deptId === 3 || authUser.deptId === 4) {
                    setToggleMenu(LibraryForPrincipalMenu);
                    setSendingMenu(budgetJSON);
                    setSelectedMenu("Budget");
                    setKirdNm("");
                }
                else {
                    setToggleMenu(PrincipalMenu);
                    setSendingMenu(budgetJSON);
                    setSelectedMenu("Budget");
                    setKirdNm("");
                }
            }
            else if (authUser.roleId === 3) {
                setToggleMenu(ClerkMenu);
                setSendingMenu(budgetJSON);
                setSelectedMenu("Budget");
                setKirdNm("");
            }
            else if (authUser.roleId === 7) {
                setToggleMenu(sansthaClerk);
                setSendingMenu(budgetSansthaJson);
                setSelectedMenu("Budget");
                setKirdNm("");
            }
            else if (authUser.roleId === 8) {
                setToggleMenu(EEAccount);
                setSendingMenu(budgetJSON);
                setSelectedMenu("Budget");
                setKirdNm("");
            }
        }
        else if (sessionMenuName === "Payroll") {
            if (authUser.roleId === 1) {
                if (authUser.deptId === 3 || authUser.deptId === 4) {
                    setToggleMenu(LibraryForPrincipalMenu);
                    setSendingMenu(payrolAcademicJSON);
                    setSelectedMenu("Payroll");
                    setKirdNm("");
                }
                else {
                    setToggleMenu(PrincipalMenu);
                    setSendingMenu(payrolAcademicJSON);
                    setSelectedMenu("Payroll");
                    setKirdNm("");
                }
            }
            else if (authUser.roleId === 3) {
                setToggleMenu(ClerkMenu);
                setSendingMenu(payrolAcademicJSON);
                setSelectedMenu("Payroll");
                setKirdNm("");
            }
        }
        else if (sessionMenuName === "Hostel") {
            if (authUser.roleId == 11) {
                setToggleMenu(onlyHostelMenu);
                setSendingMenu(HostelAcademicJSON);
                setSelectedMenu("Hostel");
                setKirdNm("");
            }
            else if (authUser.roleId === 1 || authUser.roleId === 3) {
                setToggleMenu(HostelMenu);
                setSendingMenu(HostelAcademicJSON);
                setSelectedMenu("Hostel");
                setKirdNm("");
            }
        }
        else if (sessionMenuName === "Inventory Store") {
            if (authUser.roleId === 1) {
                if (authUser.deptId === 3 || authUser.deptId === 4) {
                    setToggleMenu(LibraryForPrincipalMenu);
                    setSendingMenu(InventoryStoreJSON);
                    setSelectedMenu("Inventory Store");
                    setKirdNm("");
                }
                else {
                    setToggleMenu(PrincipalMenu);
                    setSendingMenu(InventoryStoreJSON);
                    setSelectedMenu("Inventory Store");
                    setKirdNm("");
                }
            }
            else if (authUser.roleId === 3) {
                setToggleMenu(ClerkMenu);
                setSendingMenu(InventoryStoreJSON);
                setSelectedMenu("Inventory Store");
                setKirdNm("");
            }
        }
        else {
            if (authUser.roleId === 3 && authUser.deptId === 5 && authUser.accApl === 0) {
                setToggleMenu(ClerkMenu);
                setSendingMenu(ClerkAcademicsJSON);
                setSelectedMenu("Academics");
                setKirdNm("");
            }
            else if (authUser.roleId === 3 && authUser.deptId === 5) {
                setToggleMenu(dEdCollege);
                setSendingMenu(DedCollegeJSON);
                setSelectedMenu("Account");
                setKirdNm("");
            }
            else if (authUser.roleId === 1) {
                if (authUser.deptId === 3 || authUser.deptId === 4) {
                    setToggleMenu(LibraryForPrincipalMenu);
                    setSendingMenu(PrinciaplAcademicsJSON);
                    setSelectedMenu("Academics");
                    setKirdNm("");
                }
                else if (authUser.deptId === 14) {
                    setToggleMenu(HostelMenu);
                    setSendingMenu(HostelAcademicJSON);
                    setSelectedMenu("Academics");
                    setKirdNm("");
                }
                else {
                    setToggleMenu(PrincipalMenu);
                    setSendingMenu(PrinciaplAcademicsJSON);
                    setSelectedMenu("Academics");
                    setKirdNm("");
                }
            }
            else if (authUser.roleId === 2) {
                setToggleMenu(SupervisorMenu);
                setSendingMenu(SupervisorAcademicsJSON);
                setSelectedMenu("Academics");
                setKirdNm("");
            }
            else if (authUser.roleId === 3) {
                if (authUser.deptId === 14) {
                    setToggleMenu(HostelMenu);
                    setSendingMenu(HostelAcademicJSON);
                    setSelectedMenu("Academics");
                    setKirdNm("");
                } else {
                    setToggleMenu(ClerkMenu);
                    setSendingMenu(ClerkAcademicsJSON);
                    setSelectedMenu("Academics");
                    setKirdNm("");
                }
            }
            else if (authUser.roleId === 4) {
                setToggleMenu(TeacherMenu);
                setSendingMenu(TeacherAcademicsJSON);
                setSelectedMenu("Academics");
                setKirdNm("");
            }
            else if (authUser.roleId === 5) {
                setToggleMenu(AdminMenu);
                setSendingMenu(TestingMenuId);
                setSelectedMenu("Master");
                setKirdNm("");
            }
            else if (authUser.roleId === 6) {
                setToggleMenu(sansthaAcademic);
                setSendingMenu(SansthaAcademicJSON);
                setSelectedMenu("Sanstha");
                setKirdNm("");
            }
            else if (authUser.roleId === 7) {
                setToggleMenu(sansthaClerk);
                setSendingMenu(SansthaAccountJSON);
                setSelectedMenu("Account");
                setKirdNm("");
            }
            else if (authUser.roleId === 9) {
                setToggleMenu(LibraryMenu);
                setSendingMenu(LibraryAcademicsJSON);
                setSelectedMenu("Library");
                setKirdNm("");
            }
            else if (authUser.roleId === 8) {
                setToggleMenu(EEAccount);
                setSendingMenu(EEAccountJSON);
                setSelectedMenu("Account");
                setKirdNm("");
            }
            else if (authUser.roleId === 10) {
                setToggleMenu(DengiMenu);
                setSendingMenu(DengiAccountJSON);
                setSelectedMenu("Account");
                setKirdNm("");
            }
            else if (authUser.roleId === 11) {
                setToggleMenu(onlyHostelMenu);
                setSendingMenu(HostelAcademicJSON);
                setSelectedMenu("Hostel");
                setKirdNm("");
            }
            else if (authUser.roleId === 12) {
                setToggleMenu(onlyLibraryMenu);
                setSendingMenu(LibraryAcademicsJSON);
                setSelectedMenu("Library");
                setKirdNm("");
            }

        }
    }, [authUser, num]);

    const getMenuName = (ename) => {
        setSelectedMenu(ename);
        //console.log("role id : " + authUser.roleId + "ename : " + ename);
        if (authUser.accApl === 1) {
            if (authUser.roleId === 1) {
                if (authUser.deptId === 5 || authUser.deptId === 16 || authUser.deptId === 13) {
                    if (ename === "Account") {
                        setToggleMenu(dEdCollege);
                        setSendingMenu(PrincipalAccountJSON);
                        setSelectedMenu("Account");
                        setKirdNm("");
                        navigate("/Home/ac04/0");
                    } else if (ename === "Budget") {
                        setToggleMenu(dEdCollege);
                        setSendingMenu(budgetJSON);
                        setSelectedMenu("Budget");
                        setKirdNm("");
                        navigate("/Home/BudgetDashboard");
                    }
                }
                else if (authUser.deptId === 14) {
                    if (ename === "Account") {
                        setToggleMenu(HostelMenu);
                        setSendingMenu(PrincipalAccountJSON);
                        setSelectedMenu("Account");
                        setKirdNm("");
                        navigate("/Home/ac04/0");
                    }
                    else if (ename === "Hostel") {
                        setToggleMenu(HostelMenu);
                        setSendingMenu(HostelAcademicJSON);
                        setSelectedMenu("Hostel");
                        setKirdNm("");
                        navigate("/Home/HostelDashboard");
                    }
                    else if (ename === "Budget") {
                        setToggleMenu(HostelMenu);
                        setSendingMenu(budgetJSON);
                        setSelectedMenu("Budget");
                        setKirdNm("");
                        navigate("/Home/BudgetDashboard");
                    }
                }
                else {
                    setToggleMenu(TermFee);
                    setSendingMenu(PrincipalAccountJSON);
                    setSelectedMenu("Account");
                    setKirdNm("");
                    navigate("/Home/ac04/0");
                }
            }
            else if (authUser.roleId === 3) {
                if (authUser.deptId === 16 || authUser.deptId === 13) {
                    if (ename === "Account") {
                        setToggleMenu(dEdCollege);
                        setSendingMenu(ClerkAccountJSON);
                        setSelectedMenu("Account");
                        setKirdNm("");
                        navigate("/Home/ac04/0");
                    }
                    else if (ename === "Budget") {
                        setToggleMenu(dEdCollege);
                        setSendingMenu(budgetJSON);
                        setSelectedMenu("Budget");
                        setKirdNm("");
                        navigate("/Home/BudgetDashboard");
                    }
                }
                else if (authUser.deptId === 14) {
                    if (ename === "Account") {
                        setToggleMenu(HostelMenu);
                        setSendingMenu(ClerkAccountJSON);
                        setSelectedMenu("Account");
                        setKirdNm("");
                        navigate("/Home/ac04/0");
                    }
                    else if (ename === "Hostel") {
                        setToggleMenu(HostelMenu);
                        setSendingMenu(HostelAcademicJSON);
                        setSelectedMenu("Hostel");
                        setKirdNm("");
                        navigate("/Home/HostelDashboard");
                    }
                    else if (ename === "Budget") {
                        setToggleMenu(HostelMenu);
                        setSendingMenu(budgetJSON);
                        setSelectedMenu("Budget");
                        setKirdNm("");
                        navigate("/Home/BudgetDashboard");
                    }
                }
                else if (authUser.deptId === 5) {
                    if (ename === "Account") {
                        setToggleMenu(dEdCollege);
                        setSendingMenu(DedCollegeJSON);
                        setSelectedMenu("Account");
                        setKirdNm("");
                        navigate("/Home/ac04/0");
                    }
                    else if (ename === "Budget") {
                        setToggleMenu(dEdCollege);
                        setSendingMenu(budgetJSON);
                        setSelectedMenu("Budget");
                        setKirdNm("");
                        navigate("/Home/BudgetDashboard");
                    }
                }
                else {
                    setToggleMenu(TermFee);
                    setSendingMenu(ClerkAccountJSON);
                    setSelectedMenu("Account");
                    setKirdNm("");
                    navigate("/Home/ac04/0");
                }
            }
            else if (authUser.roleId === 5) {
                setToggleMenu(TermFee);
                setSendingMenu(TestingMenuId);
                setSelectedMenu("Account");
                setKirdNm("");
                navigate("/Home/ac04/0");
            }
            else if (authUser.roleId === 7) {
                if (ename === "Account") {
                    setToggleMenu(sansthaClerk);
                    setSendingMenu(SansthaAccountJSON);
                    setSelectedMenu("Account");
                    setKirdNm("");
                    navigate("/Home/ac04/0");
                }
                else if (ename === "Budget") {
                    setToggleMenu(sansthaClerk);
                    setSendingMenu(budgetSansthaJson);
                    setSelectedMenu("Budget");
                    setKirdNm("");
                    navigate("/Home/BudgetDashboard");
                }
            }
            else if (authUser.roleId === 8) {
                if (ename === "Account") {
                    setToggleMenu(EEAccount);
                    setSendingMenu(EEAccountJSON);
                    setSelectedMenu("Account");
                    setKirdNm("");
                    navigate("/Home/ac04/0");
                }
                else if (ename === "Budget") {
                    setToggleMenu(EEAccount);
                    setSendingMenu(budgetJSON);
                    setSelectedMenu("Budget");
                    setKirdNm("");
                    navigate("/Home/BudgetDashboard");
                }
            }
            else if (authUser.roleId === 10) {
                if (ename === "Account") {
                    setToggleMenu(DengiMenu);
                    setSendingMenu(DengiAccountJSON);
                    setSelectedMenu("Account");
                    setKirdNm("");
                    navigate("/Home/f314/0");
                }
            }
        }
        else {
            if ((authUser.roleId === 3 && authUser.deptId === 5)) {
                if (ename === "Budget" && authUser.accApl === 0) {
                    setToggleMenu(ClerkMenu);
                    setSendingMenu(ClerkAccountJSON);
                    setKirdNm("");
                    navigate("/Home/BudgetDashboard");
                } else if (ename === "Budget") {
                    setToggleMenu(dEdCollege);
                    setSendingMenu(budgetJSON);
                    setKirdNm("");
                    navigate("/Home/BudgetDashboard");
                } else if (ename === "Academic" && authUser.accApl === 0) {
                    setToggleMenu(ClerkMenu);
                    setSendingMenu(ClerkAcademicsJSON);
                    setKirdNm("");
                    navigate("/Home/dashboard");
                } else if (ename === "Account" && authUser.accApl === 0) {
                    setToggleMenu(ClerkMenu);
                    setSendingMenu(ClerkAccountJSON);
                    setKirdNm(" (शाळा किर्द.)");
                    navigate("/Home/ac04/0");
                }
                else if (ename === "Account") {
                    setToggleMenu(dEdCollege);
                    setSendingMenu(DedCollegeJSON);
                    setKirdNm(" (शाळा किर्द.)");
                    navigate("/Home/ac04/0");
                }

            }
            else if (authUser.roleId === 1) {
                if (authUser.deptId == 3 || authUser.deptId == 4) {
                    if (ename === "Academic") {
                        setToggleMenu(LibraryForPrincipalMenu);
                        setSendingMenu(PrinciaplAcademicsJSON);
                        setKirdNm("");
                        navigate("/Home/dashboard");
                    }
                    else if (ename === "Account") {
                        setToggleMenu(LibraryForPrincipalMenu);
                        setSendingMenu(PrincipalAccountJSON);
                        setKirdNm(" (शाळा किर्द.)");
                        navigate("/Home/ac04/0");
                    }
                    else if (ename === "Library") {
                        setToggleMenu(LibraryForPrincipalMenu);
                        setSendingMenu(LibraryAcademicsJSON);
                        setKirdNm("");
                        navigate("/Home/LibraryDashboard");
                    }
                    else if (ename === "Mobile App") {
                        setToggleMenu(LibraryForPrincipalMenu);
                        setSendingMenu(mobileApp);
                        setKirdNm("");
                        navigate("/Home/ma1001");
                    }
                    else if (ename === "Budget") {
                        setToggleMenu(LibraryForPrincipalMenu);
                        setSendingMenu(budgetJSON);
                        setKirdNm("");
                        navigate("/Home/BudgetDashboard");
                    }
                    else if (ename === "Payroll") {
                        setToggleMenu(LibraryForPrincipalMenu);
                        setSendingMenu(payrolAcademicJSON);
                        navigate("/Home/payrollDashboard");
                        setKirdNm("");
                    }
                    else if (ename === "Inventory Store") {
                        setToggleMenu(LibraryForPrincipalMenu);
                        setSendingMenu(InventoryStoreJSON);
                        navigate("/Home/StoreDashboard");
                        setKirdNm("");
                    }
                }
                else {
                    if (ename === "Academic") {
                        setToggleMenu(PrincipalMenu);
                        setSendingMenu(PrinciaplAcademicsJSON);
                        setKirdNm("");
                        navigate("/Home/dashboard");
                    }
                    else if (ename === "Account") {
                        setToggleMenu(PrincipalMenu);
                        setSendingMenu(PrincipalAccountJSON);
                        setKirdNm(" (शाळा किर्द.)");
                        navigate("/Home/ac04/0");
                    }
                    else if (ename === "Mobile App") {
                        setToggleMenu(PrincipalMenu);
                        setSendingMenu(mobileApp);
                        setKirdNm("");
                        navigate("/Home/ma1001");
                    }
                    else if (ename === "Budget") {
                        setToggleMenu(PrincipalMenu);
                        setSendingMenu(budgetJSON);
                        setKirdNm("");
                        navigate("/Home/BudgetDashboard");
                    }
                    else if (ename === "Payroll") {
                        setToggleMenu(PrincipalMenu);
                        setSendingMenu(payrolAcademicJSON);
                        setKirdNm("");
                        navigate("/Home/payrollDashboard");
                    }
                    else if (ename === "Inventory Store") {
                        setToggleMenu(PrincipalMenu);
                        setSendingMenu(InventoryStoreJSON);
                        navigate("/Home/StoreDashboard");
                        setKirdNm("");
                    }
                }
            }
            else if (authUser.roleId === 2) {
                if (ename === "Academic") {
                    setToggleMenu(SupervisorMenu);
                    setSendingMenu(SupervisorAcademicsJSON);
                    setKirdNm("");
                    navigate("/Home/dashboard");
                }
            }
            else if (authUser.roleId === 3) {
                if (ename === "Academic") {
                    setToggleMenu(ClerkMenu);
                    setSendingMenu(ClerkAcademicsJSON);
                    setKirdNm("");
                    navigate("/Home/dashboard");
                }
                else if (ename === "Account") {
                    setToggleMenu(ClerkMenu);
                    setSendingMenu(ClerkAccountJSON);
                    setKirdNm(" (शाळा किर्द.)");
                    navigate("/Home/ac04/0");
                }
                else if (ename === "Mobile App") {
                    setToggleMenu(ClerkMenu);
                    setSendingMenu(mobileApp);
                    setKirdNm("");
                    navigate("/Home/ma1001");
                }
                else if (ename === "Budget") {
                    setToggleMenu(ClerkMenu);
                    setSendingMenu(budgetJSON);
                    setKirdNm("");
                    navigate("/Home/BudgetDashboard");
                }
                else if (ename === "Payroll") {
                    setToggleMenu(ClerkMenu);
                    setSendingMenu(payrolAcademicJSON);
                    setKirdNm("");
                    navigate("/Home/payrollDashboard");
                }
                else if (ename === "Inventory Store") {
                    setToggleMenu(ClerkMenu);
                    setSendingMenu(InventoryStoreJSON);
                    setKirdNm("");
                    navigate("/Home/StoreDashboard");
                }
            }
            else if (authUser.roleId === 4) {
                if (ename === "Academic") {
                    setToggleMenu(TeacherMenu);
                    setSendingMenu(TeacherAcademicsJSON);
                    setKirdNm("");
                    navigate("/Home/dashboard");
                }
            }
            else if (authUser.roleId === 5) {
                setToggleMenu(AdminMenu);
                setSendingMenu(TestingMenuId);
                setKirdNm("");
                navigate("/Home/dashboard");

            }
            else if (authUser.roleId === 6) {
                if (ename === "Sanstha") {
                    setToggleMenu(sansthaAcademic);
                    setSendingMenu(SansthaAcademicJSON);
                    setKirdNm("");
                    navigate("/Home/dashboard");
                }
                else if (ename === "Endowment") {
                    setToggleMenu(sansthaAcademic);
                    setSendingMenu(SansthaEndowmentJSON);
                    setKirdNm("");
                    navigate("/Home/EndowmentDash/");
                }
            }
            else if (authUser.roleId === 7) {
                if (ename === "Account") {
                    setToggleMenu(sansthaClerk);
                    setSendingMenu(SansthaAccountJSON);
                    setKirdNm("");
                    navigate("/Home/ac04/0");
                }
                else if (ename === "Budget") {
                    setToggleMenu(sansthaClerk);
                    setSendingMenu(budgetSansthaJson);
                    setKirdNm("");
                    navigate("/Home/BudgetDashboard");
                }
            }
            else if (authUser.roleId === 9) {
                if (ename === "Library") {
                    setToggleMenu(LibraryMenu);
                    setSendingMenu(LibraryAcademicsJSON);
                    setKirdNm("");
                    navigate("/Home/LibraryDashboard");
                }
            }
            else if (authUser.roleId === 8) {
                if (ename === "Account") {
                    setToggleMenu(EEAccount);
                    setSendingMenu(EEAccountJSON);
                    setKirdNm("");
                    navigate("/Home/ac04/0");
                }
                else if (ename === "Budget") {
                    setToggleMenu(EEAccount);
                    setSendingMenu(budgetJSON);
                    setKirdNm("");
                    navigate("/Home/BudgetDashboard");
                }
            }
            else if (authUser.roleId === 11) {
                setToggleMenu(onlyHostelMenu);
                setSendingMenu(HostelAcademicJSON);
                setKirdNm("");
                navigate("/Home/HostelDashboard");

            }
            else if (authUser.roleId === 12) {
                setToggleMenu(onlyLibraryMenu);
                setSendingMenu(LibraryAcademicsJSON);
                setKirdNm("");
                navigate("/Home/LibraryDashboard");
            }
            else {
                console.log("error-" + authUser.roleId + "-")
            }
        }
    }

    const refreshMenu = () => {
        document.getElementById("tbtn").click({});
        setSendingMenu([]);
        setNum(num + 1);
    }

    const [isDashboard, setIsDashboard] = useState(
        localStorage.getItem('isDashboard') === 'true' ? true : false
    );

    useEffect(() => {
        // Save the current value to localStorage whenever it changes
        localStorage.setItem('isDashboard', isDashboard.toString());
    }, [isDashboard]);

    // console.log(isDashboard)

    // Assuming you have a function to handle menu clicks
    const handleMenuClick = async (menuItemTitle, MenuItemPath) => {

        //console.log("1111111111111111111111")
        // Check if the clicked menu item is the dashboard
        if (menuItemTitle === "Dashboard" && MenuItemPath === "/Home/dashboard") {
            setIsDashboard(true);
            // Your dashboard-specific logic here
        } else {
            setIsDashboard(false);
            // Your logic for other menus here
        }     
    }


    // console.log(isDashboard);

    return (
        <>
            <nav className="navbar horizontal-layout col-lg-12 col-12 p-0">
                <div className='container d-flex flex-row'>
                    <div className='col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xs-12 mt-2'>
                        <div className='row'>
                            <table style={{ padding: "0px" }}>
                                <thead>
                                    <tr>
                                        <td rowSpan="2" style={{ width: "80px" }}>
                                            <img src={logoMini} alt="logo" id="logo" style={{ width: "70px" }} />
                                            {/* <img src={demoSchoolLogMini} alt="logo" id="logo" style={{ width: "70px" }} /> */}
                                        </td>
                                        <td style={{ verticalAlign: "bottom", fontSize: "18px", fontWeight: "600" }}>{authUser.branchName}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ fontSize: "14px", fontWeight: "700", color: "#F39C12" }}> <span style={{ color: "#BE0505" }}>{authUser.deptName}</span> <span style={{ color: "#5E50F9" }}>{kirdNm}</span> <span style={{ color: "#290101" }}>|</span> {authUser.yearLabel}</td>
                                    </tr>
                                </thead>
                            </table>

                        </div>
                    </div>

                    <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xs-12' style={{ textAlign: "right" }}>
                        {/* <!-- Example single danger button --> */}
                        <div className="btn-group">
                            <button type="button" className="btn btn-sm dropdown-toggle p-0 m-0 p-1" data-bs-toggle="dropdown" aria-expanded="false">
                                <WidgetsIcon fontSize="small" style={{ color: "#464de4" }} /> {selectedMenu}
                            </button>
                            <ul className="dropdown-menu">
                                {
                                    toggleMenu.map((e, index) => {
                                        //console.log(JSON.stringify(e))
                                        return (
                                            <li key={index} style={{ cursor: "pointer" }}>
                                                <span className="dropdown-item small"
                                                    onClick={() => {
                                                        getMenuName(e.name);
                                                        setSelectedMenu(e.name);
                                                        sessionStorage.setItem('menuName', e.name);
                                                    }}
                                                >
                                                    {e.name}
                                                </span>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>

                        <div className="btn-group ml-2">
                            <button type="button" id="toggle-btn" className="btn btn-sm dropdown-toggle p-0 m-0 p-1" data-bs-toggle="dropdown" aria-expanded="false">
                                <AccountCircleIcon fontSize="small" style={{ color: "#464de4" }} /> {authUser.roleName}
                            </button>
                            <ul className="dropdown-menu p-0 m-0 py-1">
                                <li><span className="dropdown-item small p-0 m-0 pl-2 my-1" style={{ cursor: "pointer" }} ><LockIcon style={{ color: "#464de4", fontSize: "20px" }} /> Change Password</span></li>
                                <li><span className="dropdown-item small p-0 m-0 pl-2 my-1" style={{ cursor: "pointer" }} onClick={logoutApp}><ExitToAppIcon style={{ color: "#464de4", fontSize: "20px" }} /> Logout</span></li>
                            </ul>
                        </div>

                        <button type="button" className="btn btn-light position-relative p-1 ml-2" onClick={() => navigate("/Home/s618/")}>
                            <NotificationsActiveIcon fontSize="small" className='text-primary' />
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                {notificationCount}
                            </span>
                        </button>
                    </div>
                </div>
                <div className="container d-flex flex-row">
                    <div className="navbar-menu-wrapper d-flex align-items-left">
                        <span id="tbtn" data-toggle="minimize" className="navbar-toggler align-self-center" style={{ color: "black", padding: "0px", cursor: "pointer" }} >
                            <MenuIcon fontSize="large" />
                        </span>
                    </div>
                </div>
                <div className="nav-bottom">
                    <div className="container">
                        <TestMenu sendingMenu={sendingMenu} refreshMenu={refreshMenu} showModal={showModal} setShowModal={setShowModal} authUser={authUser} handleMenuClick={handleMenuClick} />
                    </div>
                </div>
            </nav>

            {
                (systemMessageData.length > 0)
                    ?
                    (systemMessageData[0].status == 1) ?
                        <div style={{ backgroundColor: "#E01D1D", color: "#fff", fontWeight: 600, padding: 0, margin: 0 }}>
                            <marquee behavior="scroll" direction="left" onMouseOver="this.stop();" onMouseOut="this.start();">
                                {systemMessageData[0].remark}
                            </marquee>
                        </div>
                        :
                        null
                    :
                    null
            }

            {
                (studBdayData.length > 0)
                    ?
                    (isDashboard)
                        ?
                        <div className="marquee-container">
                            <marquee onMouseOver="this.stop();" onMouseOut="this.start();">
                                <div className="marquee-inner-container">
                                    <span style={{ fontSize: '16px', fontWeight: '600', marginRight: '8px' }}>Today's Birthday ( Student ) :  </span>
                                    {/* <CakeIcon className="cake-icon" style={{ marginRight: '8px' }} /> */}
                                    {studBdayData.map((student, index) => (
                                        <div key={index} className="marquee-item">
                                            <span>{(authUser.branchMedium == 1) ? student.studentNameMr : student.studentNameEn}</span>
                                            <span className="class-div">{(authUser.branchMedium == 1) ? `( ${student.classMr} ${student.divMr} )` : `( ${student.classEn} ${student.divEn} )`}</span>
                                            <CakeIcon className="cake-icon" />
                                        </div>
                                    ))}
                                </div>
                            </marquee>
                        </div>

                        :
                        null
                    :
                    null
            }


            {
                (empBdayData.length > 0)
                    ?
                    (isDashboard)
                        ?

                        <div className="marquee-container" style={{ backgroundColor: '#FD174E' }}>
                            <marquee onMouseOver="this.stop();" onMouseOut="this.start();">
                                <div className="marquee-inner-container">
                                    {/* <CakeIcon className="cake-icon" style={{ marginRight: '8px', color: '#F1948A' }} /> */}
                                    <span style={{ fontSize: '16px', fontWeight: '600', marginRight: '8px' }}>Today's Birthday ( Employee ) :  </span>
                                    {empBdayData.map((e, index) => (
                                        <div key={index} className="marquee-item">
                                            <span>
                                                {(authUser.branchMedium == 1) ? `${e.empNameMr}` : `${e.empNameEn}`}
                                            </span>
                                            <CakeIcon className="cake-icon" style={{ color: '#F1948A' }} />
                                        </div>
                                    ))}
                                </div>
                            </marquee>
                        </div>
                        :
                        null
                    :
                    null
            }

            <SansthaAdminModal showModal={showModal} setShowModal={setShowModal} sendingMenu={sendingMenu} />
        </>
    )
}

export default MasterHeader;